// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academics-js": () => import("./../../../src/pages/academics.js" /* webpackChunkName: "component---src-pages-academics-js" */),
  "component---src-pages-achievements-external-events-js": () => import("./../../../src/pages/achievements/external-events.js" /* webpackChunkName: "component---src-pages-achievements-external-events-js" */),
  "component---src-pages-admissions-js": () => import("./../../../src/pages/admissions.js" /* webpackChunkName: "component---src-pages-admissions-js" */),
  "component---src-pages-alumni-connect-js": () => import("./../../../src/pages/alumni-connect.js" /* webpackChunkName: "component---src-pages-alumni-connect-js" */),
  "component---src-pages-alumni-js": () => import("./../../../src/pages/alumni.js" /* webpackChunkName: "component---src-pages-alumni-js" */),
  "component---src-pages-annexure-2024-annexure-1-a-js": () => import("./../../../src/pages/annexure/2024/annexure-1a.js" /* webpackChunkName: "component---src-pages-annexure-2024-annexure-1-a-js" */),
  "component---src-pages-annexure-2024-annexure-1-b-js": () => import("./../../../src/pages/annexure/2024/annexure-1b.js" /* webpackChunkName: "component---src-pages-annexure-2024-annexure-1-b-js" */),
  "component---src-pages-annexure-2024-annexure-10-js": () => import("./../../../src/pages/annexure/2024/annexure-10.js" /* webpackChunkName: "component---src-pages-annexure-2024-annexure-10-js" */),
  "component---src-pages-annexure-2024-annexure-11-js": () => import("./../../../src/pages/annexure/2024/annexure-11.js" /* webpackChunkName: "component---src-pages-annexure-2024-annexure-11-js" */),
  "component---src-pages-annexure-2024-annexure-12-js": () => import("./../../../src/pages/annexure/2024/annexure-12.js" /* webpackChunkName: "component---src-pages-annexure-2024-annexure-12-js" */),
  "component---src-pages-annexure-2024-annexure-13-js": () => import("./../../../src/pages/annexure/2024/annexure-13.js" /* webpackChunkName: "component---src-pages-annexure-2024-annexure-13-js" */),
  "component---src-pages-annexure-2024-annexure-2-js": () => import("./../../../src/pages/annexure/2024/annexure-2.js" /* webpackChunkName: "component---src-pages-annexure-2024-annexure-2-js" */),
  "component---src-pages-annexure-2024-annexure-3-4-js": () => import("./../../../src/pages/annexure/2024/annexure-3-4.js" /* webpackChunkName: "component---src-pages-annexure-2024-annexure-3-4-js" */),
  "component---src-pages-annexure-2024-annexure-5-js": () => import("./../../../src/pages/annexure/2024/annexure-5.js" /* webpackChunkName: "component---src-pages-annexure-2024-annexure-5-js" */),
  "component---src-pages-annexure-2024-annexure-6-js": () => import("./../../../src/pages/annexure/2024/annexure-6.js" /* webpackChunkName: "component---src-pages-annexure-2024-annexure-6-js" */),
  "component---src-pages-annexure-2024-annexure-7-js": () => import("./../../../src/pages/annexure/2024/annexure-7.js" /* webpackChunkName: "component---src-pages-annexure-2024-annexure-7-js" */),
  "component---src-pages-annexure-2024-annexure-8-js": () => import("./../../../src/pages/annexure/2024/annexure-8.js" /* webpackChunkName: "component---src-pages-annexure-2024-annexure-8-js" */),
  "component---src-pages-annexure-2024-annexure-9-js": () => import("./../../../src/pages/annexure/2024/annexure-9.js" /* webpackChunkName: "component---src-pages-annexure-2024-annexure-9-js" */),
  "component---src-pages-annexure-2024-mandatory-public-disclosure-js": () => import("./../../../src/pages/annexure/2024/mandatory-public-disclosure.js" /* webpackChunkName: "component---src-pages-annexure-2024-mandatory-public-disclosure-js" */),
  "component---src-pages-annexure-affiliation-upgradation-extension-of-affiliation-letter-js": () => import("./../../../src/pages/annexure/affiliation-upgradation-extension-of-affiliation-letter.js" /* webpackChunkName: "component---src-pages-annexure-affiliation-upgradation-extension-of-affiliation-letter-js" */),
  "component---src-pages-annexure-affiliation-upgradation-letter-js": () => import("./../../../src/pages/annexure/affiliation-upgradation-letter.js" /* webpackChunkName: "component---src-pages-annexure-affiliation-upgradation-letter-js" */),
  "component---src-pages-annexure-annual-academic-calendar-2023-24-js": () => import("./../../../src/pages/annexure/annual-academic-calendar-2023-24.js" /* webpackChunkName: "component---src-pages-annexure-annual-academic-calendar-2023-24-js" */),
  "component---src-pages-annexure-building-safety-certificate-js": () => import("./../../../src/pages/annexure/building-safety-certificate.js" /* webpackChunkName: "component---src-pages-annexure-building-safety-certificate-js" */),
  "component---src-pages-annexure-copies-of-societies-trust-company-registration-renewal-certificate-js": () => import("./../../../src/pages/annexure/copies-of-societies-trust-company-registration-renewal-certificate.js" /* webpackChunkName: "component---src-pages-annexure-copies-of-societies-trust-company-registration-renewal-certificate-js" */),
  "component---src-pages-annexure-extension-of-provisional-affiliation-js": () => import("./../../../src/pages/annexure/extension-of-provisional-affiliation.js" /* webpackChunkName: "component---src-pages-annexure-extension-of-provisional-affiliation-js" */),
  "component---src-pages-annexure-fee-structure-session-2023-2024-js": () => import("./../../../src/pages/annexure/fee-structure-session-2023-2024.js" /* webpackChunkName: "component---src-pages-annexure-fee-structure-session-2023-2024-js" */),
  "component---src-pages-annexure-fire-safety-certificate-js": () => import("./../../../src/pages/annexure/fire-safety-certificate.js" /* webpackChunkName: "component---src-pages-annexure-fire-safety-certificate-js" */),
  "component---src-pages-annexure-health-certificate-js": () => import("./../../../src/pages/annexure/health-certificate.js" /* webpackChunkName: "component---src-pages-annexure-health-certificate-js" */),
  "component---src-pages-annexure-last-three-years-result-of-the-board-examination-js": () => import("./../../../src/pages/annexure/last-three-years-result-of-the-board-examination.js" /* webpackChunkName: "component---src-pages-annexure-last-three-years-result-of-the-board-examination-js" */),
  "component---src-pages-annexure-list-of-parents-teachers-association-js": () => import("./../../../src/pages/annexure/list-of-parents-teachers-association.js" /* webpackChunkName: "component---src-pages-annexure-list-of-parents-teachers-association-js" */),
  "component---src-pages-annexure-list-of-school-management-committee-js": () => import("./../../../src/pages/annexure/list-of-school-management-committee.js" /* webpackChunkName: "component---src-pages-annexure-list-of-school-management-committee-js" */),
  "component---src-pages-annexure-no-objection-certificate-js": () => import("./../../../src/pages/annexure/no-objection-certificate.js" /* webpackChunkName: "component---src-pages-annexure-no-objection-certificate-js" */),
  "component---src-pages-annexure-recognition-certificate-js": () => import("./../../../src/pages/annexure/recognition-certificate.js" /* webpackChunkName: "component---src-pages-annexure-recognition-certificate-js" */),
  "component---src-pages-atl-js": () => import("./../../../src/pages/atl.js" /* webpackChunkName: "component---src-pages-atl-js" */),
  "component---src-pages-best-cbse-school-in-delhi-admission-index-js": () => import("./../../../src/pages/best-cbse-school-in-delhi-admission/index.js" /* webpackChunkName: "component---src-pages-best-cbse-school-in-delhi-admission-index-js" */),
  "component---src-pages-best-cbse-school-in-delhi-admission-success-js": () => import("./../../../src/pages/best-cbse-school-in-delhi-admission/success.js" /* webpackChunkName: "component---src-pages-best-cbse-school-in-delhi-admission-success-js" */),
  "component---src-pages-blog-chinmaya-vidyalaya-school-with-a-difference-js": () => import("./../../../src/pages/blog/chinmaya-vidyalaya-school-with-a-difference.js" /* webpackChunkName: "component---src-pages-blog-chinmaya-vidyalaya-school-with-a-difference-js" */),
  "component---src-pages-blog-holistic-development-the-purpose-of-education-js": () => import("./../../../src/pages/blog/holistic-development-the-purpose-of-education.js" /* webpackChunkName: "component---src-pages-blog-holistic-development-the-purpose-of-education-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-janani-janmabhoomi-swargadapi-gariyasi-js": () => import("./../../../src/pages/blog/janani-janmabhoomi-swargadapi-gariyasi.js" /* webpackChunkName: "component---src-pages-blog-janani-janmabhoomi-swargadapi-gariyasi-js" */),
  "component---src-pages-blog-kindness-goals-js": () => import("./../../../src/pages/blog/kindness-goals.js" /* webpackChunkName: "component---src-pages-blog-kindness-goals-js" */),
  "component---src-pages-blog-nurturing-cyber-savvy-kids-js": () => import("./../../../src/pages/blog/nurturing-cyber-savvy-kids.js" /* webpackChunkName: "component---src-pages-blog-nurturing-cyber-savvy-kids-js" */),
  "component---src-pages-blog-understanding-chinmaya-vision-programme-js": () => import("./../../../src/pages/blog/understanding-chinmaya-vision-programme.js" /* webpackChunkName: "component---src-pages-blog-understanding-chinmaya-vision-programme-js" */),
  "component---src-pages-bus-routes-js": () => import("./../../../src/pages/bus-routes.js" /* webpackChunkName: "component---src-pages-bus-routes-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cbse-corner-js": () => import("./../../../src/pages/cbse-corner.js" /* webpackChunkName: "component---src-pages-cbse-corner-js" */),
  "component---src-pages-cbse-results-js": () => import("./../../../src/pages/cbse-results.js" /* webpackChunkName: "component---src-pages-cbse-results-js" */),
  "component---src-pages-chinmaya-mission-trust-js": () => import("./../../../src/pages/chinmaya-mission-trust.js" /* webpackChunkName: "component---src-pages-chinmaya-mission-trust-js" */),
  "component---src-pages-chinmaya-vision-programme-js": () => import("./../../../src/pages/chinmaya-vision-programme.js" /* webpackChunkName: "component---src-pages-chinmaya-vision-programme-js" */),
  "component---src-pages-circulars-js": () => import("./../../../src/pages/circulars.js" /* webpackChunkName: "component---src-pages-circulars-js" */),
  "component---src-pages-club-js": () => import("./../../../src/pages/club.js" /* webpackChunkName: "component---src-pages-club-js" */),
  "component---src-pages-contact-information-js": () => import("./../../../src/pages/contact-information.js" /* webpackChunkName: "component---src-pages-contact-information-js" */),
  "component---src-pages-e-newsletters-js": () => import("./../../../src/pages/e-newsletters.js" /* webpackChunkName: "component---src-pages-e-newsletters-js" */),
  "component---src-pages-exam-schedule-js": () => import("./../../../src/pages/exam-schedule.js" /* webpackChunkName: "component---src-pages-exam-schedule-js" */),
  "component---src-pages-fee-structure-js": () => import("./../../../src/pages/fee-structure.js" /* webpackChunkName: "component---src-pages-fee-structure-js" */),
  "component---src-pages-gurudevs-journey-js": () => import("./../../../src/pages/gurudevs-journey.js" /* webpackChunkName: "component---src-pages-gurudevs-journey-js" */),
  "component---src-pages-hall-of-fame-js": () => import("./../../../src/pages/hall-of-fame.js" /* webpackChunkName: "component---src-pages-hall-of-fame-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infrastructure-js": () => import("./../../../src/pages/infrastructure.js" /* webpackChunkName: "component---src-pages-infrastructure-js" */),
  "component---src-pages-laboratories-js": () => import("./../../../src/pages/laboratories.js" /* webpackChunkName: "component---src-pages-laboratories-js" */),
  "component---src-pages-library-js": () => import("./../../../src/pages/library.js" /* webpackChunkName: "component---src-pages-library-js" */),
  "component---src-pages-link-tree-js": () => import("./../../../src/pages/link-tree.js" /* webpackChunkName: "component---src-pages-link-tree-js" */),
  "component---src-pages-managing-committee-js": () => import("./../../../src/pages/managing-committee.js" /* webpackChunkName: "component---src-pages-managing-committee-js" */),
  "component---src-pages-mandatory-public-disclosure-appendix-ix-js": () => import("./../../../src/pages/mandatory-public-disclosure-appendix-IX.js" /* webpackChunkName: "component---src-pages-mandatory-public-disclosure-appendix-ix-js" */),
  "component---src-pages-nursery-admissions-js": () => import("./../../../src/pages/nursery-admissions.js" /* webpackChunkName: "component---src-pages-nursery-admissions-js" */),
  "component---src-pages-online-registration-process-js": () => import("./../../../src/pages/online-registration-process.js" /* webpackChunkName: "component---src-pages-online-registration-process-js" */),
  "component---src-pages-performing-arts-js": () => import("./../../../src/pages/performing-arts.js" /* webpackChunkName: "component---src-pages-performing-arts-js" */),
  "component---src-pages-podcasts-index-js": () => import("./../../../src/pages/podcasts/index.js" /* webpackChunkName: "component---src-pages-podcasts-index-js" */),
  "component---src-pages-podcasts-maha-samadhi-divas-2024-index-js": () => import("./../../../src/pages/podcasts/maha-samadhi-divas-2024/index.js" /* webpackChunkName: "component---src-pages-podcasts-maha-samadhi-divas-2024-index-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-principal-message-js": () => import("./../../../src/pages/principal-message.js" /* webpackChunkName: "component---src-pages-principal-message-js" */),
  "component---src-pages-pta-executive-committee-js": () => import("./../../../src/pages/pta-executive-committee.js" /* webpackChunkName: "component---src-pages-pta-executive-committee-js" */),
  "component---src-pages-registrations-js": () => import("./../../../src/pages/registrations.js" /* webpackChunkName: "component---src-pages-registrations-js" */),
  "component---src-pages-rules-and-regulations-js": () => import("./../../../src/pages/rules-and-regulations.js" /* webpackChunkName: "component---src-pages-rules-and-regulations-js" */),
  "component---src-pages-school-council-js": () => import("./../../../src/pages/school-council.js" /* webpackChunkName: "component---src-pages-school-council-js" */),
  "component---src-pages-school-events-11-th-national-level-exhibition-and-project-competition-nlepc-2024-js": () => import("./../../../src/pages/school-events/11th-national-level-exhibition-and-project-competition-nlepc-2024.js" /* webpackChunkName: "component---src-pages-school-events-11-th-national-level-exhibition-and-project-competition-nlepc-2024-js" */),
  "component---src-pages-school-events-75th-republic-day-celebration-js": () => import("./../../../src/pages/school-events/75th-republic-day-celebration.js" /* webpackChunkName: "component---src-pages-school-events-75th-republic-day-celebration-js" */),
  "component---src-pages-school-events-a-bootcamp-on-design-thinking-for-class-x-2024-js": () => import("./../../../src/pages/school-events/a-bootcamp-on-design-thinking-for-class-x-2024.js" /* webpackChunkName: "component---src-pages-school-events-a-bootcamp-on-design-thinking-for-class-x-2024-js" */),
  "component---src-pages-school-events-a-day-of-fun-and-imagination-movie-2024-js": () => import("./../../../src/pages/school-events/a-day-of-fun-and-imagination-movie-2024.js" /* webpackChunkName: "component---src-pages-school-events-a-day-of-fun-and-imagination-movie-2024-js" */),
  "component---src-pages-school-events-a-teachers-journey-of-self-discovery-staff-visit-to-chinmaya-mission-2024-js": () => import("./../../../src/pages/school-events/a-teachers-journey-of-self-discovery-staff-visit-to-chinmaya-mission-2024.js" /* webpackChunkName: "component---src-pages-school-events-a-teachers-journey-of-self-discovery-staff-visit-to-chinmaya-mission-2024-js" */),
  "component---src-pages-school-events-alt-community-day-2024-js": () => import("./../../../src/pages/school-events/alt-community-day-2024.js" /* webpackChunkName: "component---src-pages-school-events-alt-community-day-2024-js" */),
  "component---src-pages-school-events-alumni-connect-session-2023-js": () => import("./../../../src/pages/school-events/alumni-connect-session-2023.js" /* webpackChunkName: "component---src-pages-school-events-alumni-connect-session-2023-js" */),
  "component---src-pages-school-events-alumni-connect-session-december-2023-js": () => import("./../../../src/pages/school-events/alumni-connect-session-december-2023.js" /* webpackChunkName: "component---src-pages-school-events-alumni-connect-session-december-2023-js" */),
  "component---src-pages-school-events-animal-race-2024-js": () => import("./../../../src/pages/school-events/animal-race-2024.js" /* webpackChunkName: "component---src-pages-school-events-animal-race-2024-js" */),
  "component---src-pages-school-events-aranya-rakshak-jadhav-payeng-js": () => import("./../../../src/pages/school-events/aranya-rakshak-jadhav-payeng.js" /* webpackChunkName: "component---src-pages-school-events-aranya-rakshak-jadhav-payeng-js" */),
  "component---src-pages-school-events-ashirwad-ceremony-of-class-12-th-10th-2024-js": () => import("./../../../src/pages/school-events/ashirwad-ceremony-of-class-12th-&-10th-2024.js" /* webpackChunkName: "component---src-pages-school-events-ashirwad-ceremony-of-class-12-th-10th-2024-js" */),
  "component---src-pages-school-events-assembly-by-class-iiic-the-power-of-kindness-2024-js": () => import("./../../../src/pages/school-events/assembly-by-class-iiic-the-power-of-kindness-2024.js" /* webpackChunkName: "component---src-pages-school-events-assembly-by-class-iiic-the-power-of-kindness-2024-js" */),
  "component---src-pages-school-events-assembly-by-class-iv-c-books-are-our-best-friends-2024-js": () => import("./../../../src/pages/school-events/assembly-by-class-iv-c-books-are-our-best-friends-2024.js" /* webpackChunkName: "component---src-pages-school-events-assembly-by-class-iv-c-books-are-our-best-friends-2024-js" */),
  "component---src-pages-school-events-assembly-on-christmas-by-primary-2023-js": () => import("./../../../src/pages/school-events/assembly-on-christmas-by-primary-2023.js" /* webpackChunkName: "component---src-pages-school-events-assembly-on-christmas-by-primary-2023-js" */),
  "component---src-pages-school-events-assembly-on-tapovan-and-geeta-jayanti-by-middle-and-senior-classes-2023-js": () => import("./../../../src/pages/school-events/assembly-on-tapovan-and-geeta-jayanti-by-middle-and-senior-classes-2023.js" /* webpackChunkName: "component---src-pages-school-events-assembly-on-tapovan-and-geeta-jayanti-by-middle-and-senior-classes-2023-js" */),
  "component---src-pages-school-events-astroport-trip-to-sariska-2024-js": () => import("./../../../src/pages/school-events/astroport-trip-to-sariska-2024.js" /* webpackChunkName: "component---src-pages-school-events-astroport-trip-to-sariska-2024-js" */),
  "component---src-pages-school-events-atulya-bharat-amulya-bharat-js": () => import("./../../../src/pages/school-events/atulya-bharat-amulya-bharat.js" /* webpackChunkName: "component---src-pages-school-events-atulya-bharat-amulya-bharat-js" */),
  "component---src-pages-school-events-azadi-ka-amrit-mahotsav-2023-js": () => import("./../../../src/pages/school-events/azadi-ka-amrit-mahotsav-2023.js" /* webpackChunkName: "component---src-pages-school-events-azadi-ka-amrit-mahotsav-2023-js" */),
  "component---src-pages-school-events-baishakhi-celebration-2024-js": () => import("./../../../src/pages/school-events/baishakhi-celebration-2024.js" /* webpackChunkName: "component---src-pages-school-events-baishakhi-celebration-2024-js" */),
  "component---src-pages-school-events-bhartiya-bhasha-diwas-2023-js": () => import("./../../../src/pages/school-events/bhartiya-bhasha-diwas-2023.js" /* webpackChunkName: "component---src-pages-school-events-bhartiya-bhasha-diwas-2023-js" */),
  "component---src-pages-school-events-bucket-ball-toss-activity-2024-js": () => import("./../../../src/pages/school-events/bucket-ball-toss-activity-2024.js" /* webpackChunkName: "component---src-pages-school-events-bucket-ball-toss-activity-2024-js" */),
  "component---src-pages-school-events-buzzing-bee-pre-primary-department-2024-js": () => import("./../../../src/pages/school-events/buzzing-bee-pre-primary-department-2024.js" /* webpackChunkName: "component---src-pages-school-events-buzzing-bee-pre-primary-department-2024-js" */),
  "component---src-pages-school-events-capacity-building-workshop-2024-js": () => import("./../../../src/pages/school-events/capacity-building-workshop-2024.js" /* webpackChunkName: "component---src-pages-school-events-capacity-building-workshop-2024-js" */),
  "component---src-pages-school-events-career-counselling-session-2024-js": () => import("./../../../src/pages/school-events/career-counselling-session-2024.js" /* webpackChunkName: "component---src-pages-school-events-career-counselling-session-2024-js" */),
  "component---src-pages-school-events-career-fair-at-chinmaya-vidyalaya-2024-js": () => import("./../../../src/pages/school-events/career-fair-at-chinmaya-vidyalaya-2024.js" /* webpackChunkName: "component---src-pages-school-events-career-fair-at-chinmaya-vidyalaya-2024-js" */),
  "component---src-pages-school-events-celebrating-gurus-2024-js": () => import("./../../../src/pages/school-events/celebrating-gurus-2024.js" /* webpackChunkName: "component---src-pages-school-events-celebrating-gurus-2024-js" */),
  "component---src-pages-school-events-chandigarh-trip-classes-iv-v-2023-js": () => import("./../../../src/pages/school-events/chandigarh-trip-classes-iv-v-2023.js" /* webpackChunkName: "component---src-pages-school-events-chandigarh-trip-classes-iv-v-2023-js" */),
  "component---src-pages-school-events-chess-inaugural-session-2024-js": () => import("./../../../src/pages/school-events/chess-inaugural-session-2024.js" /* webpackChunkName: "component---src-pages-school-events-chess-inaugural-session-2024-js" */),
  "component---src-pages-school-events-class-iv-global-climate-awakening-js": () => import("./../../../src/pages/school-events/class-iv-global-climate-awakening.js" /* webpackChunkName: "component---src-pages-school-events-class-iv-global-climate-awakening-js" */),
  "component---src-pages-school-events-class-xii-presentation-kartavya-parayan-2024-js": () => import("./../../../src/pages/school-events/class-xii-presentation-kartavya-parayan-2024.js" /* webpackChunkName: "component---src-pages-school-events-class-xii-presentation-kartavya-parayan-2024-js" */),
  "component---src-pages-school-events-community-helpers-visit-2024-js": () => import("./../../../src/pages/school-events/community-helpers-visit-2024.js" /* webpackChunkName: "component---src-pages-school-events-community-helpers-visit-2024-js" */),
  "component---src-pages-school-events-confluence-career-fair-2024-js": () => import("./../../../src/pages/school-events/confluence-career-fair-2024.js" /* webpackChunkName: "component---src-pages-school-events-confluence-career-fair-2024-js" */),
  "component---src-pages-school-events-connecting-with-community-2024-js": () => import("./../../../src/pages/school-events/connecting-with-community-2024.js" /* webpackChunkName: "component---src-pages-school-events-connecting-with-community-2024-js" */),
  "component---src-pages-school-events-counting-steps-towards-net-zero-js": () => import("./../../../src/pages/school-events/counting-steps-towards-net-zero.js" /* webpackChunkName: "component---src-pages-school-events-counting-steps-towards-net-zero-js" */),
  "component---src-pages-school-events-cyber-safety-awareness-session-2024-js": () => import("./../../../src/pages/school-events/cyber-safety-awareness-session-2024.js" /* webpackChunkName: "component---src-pages-school-events-cyber-safety-awareness-session-2024-js" */),
  "component---src-pages-school-events-cyber-security-workshop-for-teacher-2024-js": () => import("./../../../src/pages/school-events/cyber-security-workshop-for-teacher-2024.js" /* webpackChunkName: "component---src-pages-school-events-cyber-security-workshop-for-teacher-2024-js" */),
  "component---src-pages-school-events-district-park-excursion-js": () => import("./../../../src/pages/school-events/district-park-excursion.js" /* webpackChunkName: "component---src-pages-school-events-district-park-excursion-js" */),
  "component---src-pages-school-events-diwali-celebration-primary-pre-primary-2024-js": () => import("./../../../src/pages/school-events/diwali-celebration-primary-pre-primary-2024.js" /* webpackChunkName: "component---src-pages-school-events-diwali-celebration-primary-pre-primary-2024-js" */),
  "component---src-pages-school-events-dussehra-celebration-2023-js": () => import("./../../../src/pages/school-events/dussehra-celebration-2023.js" /* webpackChunkName: "component---src-pages-school-events-dussehra-celebration-2023-js" */),
  "component---src-pages-school-events-eco-club-activity-2024-js": () => import("./../../../src/pages/school-events/eco-club-activity-2024.js" /* webpackChunkName: "component---src-pages-school-events-eco-club-activity-2024-js" */),
  "component---src-pages-school-events-educational-trip-to-astroport-sariska-2024-js": () => import("./../../../src/pages/school-events/educational-trip-to-astroport-sariska-2024.js" /* webpackChunkName: "component---src-pages-school-events-educational-trip-to-astroport-sariska-2024-js" */),
  "component---src-pages-school-events-educational-trip-to-infosys-noida-2024-js": () => import("./../../../src/pages/school-events/educational-trip-to-infosys-noida-2024.js" /* webpackChunkName: "component---src-pages-school-events-educational-trip-to-infosys-noida-2024-js" */),
  "component---src-pages-school-events-evacuation-mock-drills-from-classes-nursery-to-xii-2024-js": () => import("./../../../src/pages/school-events/evacuation-mock-drills-from-classes-nursery-to-xii-2024.js" /* webpackChunkName: "component---src-pages-school-events-evacuation-mock-drills-from-classes-nursery-to-xii-2024-js" */),
  "component---src-pages-school-events-excursion-movie-2024-js": () => import("./../../../src/pages/school-events/excursion-movie-2024.js" /* webpackChunkName: "component---src-pages-school-events-excursion-movie-2024-js" */),
  "component---src-pages-school-events-excursion-to-purana-qila-old-fort-visit-2024-js": () => import("./../../../src/pages/school-events/excursion-to-purana-qila-old-fort-visit-2024.js" /* webpackChunkName: "component---src-pages-school-events-excursion-to-purana-qila-old-fort-visit-2024-js" */),
  "component---src-pages-school-events-experiential-learning-workship-2024-js": () => import("./../../../src/pages/school-events/experiential-learning-workship-2024.js" /* webpackChunkName: "component---src-pages-school-events-experiential-learning-workship-2024-js" */),
  "component---src-pages-school-events-farewell-to-class-12-2023-js": () => import("./../../../src/pages/school-events/farewell-to-class-12-2023.js" /* webpackChunkName: "component---src-pages-school-events-farewell-to-class-12-2023-js" */),
  "component---src-pages-school-events-fathers-day-2024-js": () => import("./../../../src/pages/school-events/fathers-day-2024.js" /* webpackChunkName: "component---src-pages-school-events-fathers-day-2024-js" */),
  "component---src-pages-school-events-fathers-day-celebration-2023-js": () => import("./../../../src/pages/school-events/fathers-day-celebration-2023.js" /* webpackChunkName: "component---src-pages-school-events-fathers-day-celebration-2023-js" */),
  "component---src-pages-school-events-financial-safety-in-cyberspace-2024-js": () => import("./../../../src/pages/school-events/financial-safety-in-cyberspace-2024.js" /* webpackChunkName: "component---src-pages-school-events-financial-safety-in-cyberspace-2024-js" */),
  "component---src-pages-school-events-first-aid-and-bls-workshop-js": () => import("./../../../src/pages/school-events/first-aid-and-bls-workshop.js" /* webpackChunkName: "component---src-pages-school-events-first-aid-and-bls-workshop-js" */),
  "component---src-pages-school-events-first-day-fun-js": () => import("./../../../src/pages/school-events/first-day-fun.js" /* webpackChunkName: "component---src-pages-school-events-first-day-fun-js" */),
  "component---src-pages-school-events-friendship-day-celebration-2024-js": () => import("./../../../src/pages/school-events/friendship-day-celebration-2024.js" /* webpackChunkName: "component---src-pages-school-events-friendship-day-celebration-2024-js" */),
  "component---src-pages-school-events-friendship-day-js": () => import("./../../../src/pages/school-events/friendship-day.js" /* webpackChunkName: "component---src-pages-school-events-friendship-day-js" */),
  "component---src-pages-school-events-fun-frolic-sports-week-2023-js": () => import("./../../../src/pages/school-events/fun-frolic-sports-week-2023.js" /* webpackChunkName: "component---src-pages-school-events-fun-frolic-sports-week-2023-js" */),
  "component---src-pages-school-events-gandhi-jayanti-2024-js": () => import("./../../../src/pages/school-events/gandhi-jayanti-2024.js" /* webpackChunkName: "component---src-pages-school-events-gandhi-jayanti-2024-js" */),
  "component---src-pages-school-events-gandhi-jayanti-celebration-2023-js": () => import("./../../../src/pages/school-events/gandhi-jayanti-celebration-2023.js" /* webpackChunkName: "component---src-pages-school-events-gandhi-jayanti-celebration-2023-js" */),
  "component---src-pages-school-events-ganpati-puja-and-assembly-2024-js": () => import("./../../../src/pages/school-events/ganpati-puja-and-assembly-2024.js" /* webpackChunkName: "component---src-pages-school-events-ganpati-puja-and-assembly-2024-js" */),
  "component---src-pages-school-events-garbology-workshop-for-students-by-green-dream-foundation-2024-js": () => import("./../../../src/pages/school-events/garbology-workshop-for-students-by-green-dream-foundation-2024.js" /* webpackChunkName: "component---src-pages-school-events-garbology-workshop-for-students-by-green-dream-foundation-2024-js" */),
  "component---src-pages-school-events-gayatri-havan-on-makarsankranti-2024-js": () => import("./../../../src/pages/school-events/gayatri-havan-on-makarsankranti-2024.js" /* webpackChunkName: "component---src-pages-school-events-gayatri-havan-on-makarsankranti-2024-js" */),
  "component---src-pages-school-events-global-handwashing-day-2023-js": () => import("./../../../src/pages/school-events/global-handwashing-day-2023.js" /* webpackChunkName: "component---src-pages-school-events-global-handwashing-day-2023-js" */),
  "component---src-pages-school-events-global-handwashing-day-2024-js": () => import("./../../../src/pages/school-events/global-handwashing-day-2024.js" /* webpackChunkName: "component---src-pages-school-events-global-handwashing-day-2024-js" */),
  "component---src-pages-school-events-global-herb-plantation-drive-2024-js": () => import("./../../../src/pages/school-events/global-herb-plantation-drive-2024.js" /* webpackChunkName: "component---src-pages-school-events-global-herb-plantation-drive-2024-js" */),
  "component---src-pages-school-events-grandparents-day-celebration-2023-js": () => import("./../../../src/pages/school-events/grandparents-day-celebration-2023.js" /* webpackChunkName: "component---src-pages-school-events-grandparents-day-celebration-2023-js" */),
  "component---src-pages-school-events-grandparents-day-celebration-2024-js": () => import("./../../../src/pages/school-events/grandparents-day-celebration-2024.js" /* webpackChunkName: "component---src-pages-school-events-grandparents-day-celebration-2024-js" */),
  "component---src-pages-school-events-gratitude-should-be-the-attitude-assembly-2024-js": () => import("./../../../src/pages/school-events/gratitude-should-be-the-attitude-assembly-2024.js" /* webpackChunkName: "component---src-pages-school-events-gratitude-should-be-the-attitude-assembly-2024-js" */),
  "component---src-pages-school-events-green-day-celebration-2023-js": () => import("./../../../src/pages/school-events/green-day-celebration-2023.js" /* webpackChunkName: "component---src-pages-school-events-green-day-celebration-2023-js" */),
  "component---src-pages-school-events-gurpurab-celebration-2023-js": () => import("./../../../src/pages/school-events/gurpurab-celebration-2023.js" /* webpackChunkName: "component---src-pages-school-events-gurpurab-celebration-2023-js" */),
  "component---src-pages-school-events-gurpurab-celebrations-pre-primary-primary-2024-js": () => import("./../../../src/pages/school-events/gurpurab-celebrations-pre-primary-primary-2024.js" /* webpackChunkName: "component---src-pages-school-events-gurpurab-celebrations-pre-primary-primary-2024-js" */),
  "component---src-pages-school-events-guru-purnima-celebrations-2023-js": () => import("./../../../src/pages/school-events/guru-purnima-celebrations-2023.js" /* webpackChunkName: "component---src-pages-school-events-guru-purnima-celebrations-2023-js" */),
  "component---src-pages-school-events-gurudevs-mahasamadhi-diwas-2024-js": () => import("./../../../src/pages/school-events/gurudevs-mahasamadhi-diwas-2024.js" /* webpackChunkName: "component---src-pages-school-events-gurudevs-mahasamadhi-diwas-2024-js" */),
  "component---src-pages-school-events-hanuman-jayanti-2024-js": () => import("./../../../src/pages/school-events/hanuman-jayanti-2024.js" /* webpackChunkName: "component---src-pages-school-events-hanuman-jayanti-2024-js" */),
  "component---src-pages-school-events-hindi-diwas-2023-js": () => import("./../../../src/pages/school-events/hindi-diwas-2023.js" /* webpackChunkName: "component---src-pages-school-events-hindi-diwas-2023-js" */),
  "component---src-pages-school-events-hindi-diwas-2024-js": () => import("./../../../src/pages/school-events/hindi-diwas-2024.js" /* webpackChunkName: "component---src-pages-school-events-hindi-diwas-2024-js" */),
  "component---src-pages-school-events-independence-day-assembly-2024-js": () => import("./../../../src/pages/school-events/independence-day-assembly-2024.js" /* webpackChunkName: "component---src-pages-school-events-independence-day-assembly-2024-js" */),
  "component---src-pages-school-events-independence-day-celebration-2023-js": () => import("./../../../src/pages/school-events/independence-day-celebration-2023.js" /* webpackChunkName: "component---src-pages-school-events-independence-day-celebration-2023-js" */),
  "component---src-pages-school-events-independence-day-pre-primary-2024-js": () => import("./../../../src/pages/school-events/independence-day-pre-primary-2024.js" /* webpackChunkName: "component---src-pages-school-events-independence-day-pre-primary-2024-js" */),
  "component---src-pages-school-events-independence-day-primary-2024-js": () => import("./../../../src/pages/school-events/independence-day-primary-2024.js" /* webpackChunkName: "component---src-pages-school-events-independence-day-primary-2024-js" */),
  "component---src-pages-school-events-index-js": () => import("./../../../src/pages/school-events/index.js" /* webpackChunkName: "component---src-pages-school-events-index-js" */),
  "component---src-pages-school-events-inter-house-coding-competition-2024-js": () => import("./../../../src/pages/school-events/inter-house-coding-competition-2024.js" /* webpackChunkName: "component---src-pages-school-events-inter-house-coding-competition-2024-js" */),
  "component---src-pages-school-events-inter-house-cricket-match-2024-js": () => import("./../../../src/pages/school-events/inter-house-cricket-match-2024.js" /* webpackChunkName: "component---src-pages-school-events-inter-house-cricket-match-2024-js" */),
  "component---src-pages-school-events-inter-house-drill-competition-session-2024-js": () => import("./../../../src/pages/school-events/inter-house-drill-competition-session-2024.js" /* webpackChunkName: "component---src-pages-school-events-inter-house-drill-competition-session-2024-js" */),
  "component---src-pages-school-events-inter-house-english-poetry-recitation-competition-classes-vi-viii-2024-js": () => import("./../../../src/pages/school-events/inter-house-english-poetry-recitation-competition-classes-vi-viii-2024.js" /* webpackChunkName: "component---src-pages-school-events-inter-house-english-poetry-recitation-competition-classes-vi-viii-2024-js" */),
  "component---src-pages-school-events-inter-house-football-matches-2024-js": () => import("./../../../src/pages/school-events/inter-house-football-matches-2024.js" /* webpackChunkName: "component---src-pages-school-events-inter-house-football-matches-2024-js" */),
  "component---src-pages-school-events-inter-house-hindi-poetry-recitation-competition-2024-js": () => import("./../../../src/pages/school-events/inter-house-hindi-poetry-recitation-competition-2024.js" /* webpackChunkName: "component---src-pages-school-events-inter-house-hindi-poetry-recitation-competition-2024-js" */),
  "component---src-pages-school-events-inter-house-janmashtami-dance-competition-2024-js": () => import("./../../../src/pages/school-events/inter-house-janmashtami-dance-competition-2024.js" /* webpackChunkName: "component---src-pages-school-events-inter-house-janmashtami-dance-competition-2024-js" */),
  "component---src-pages-school-events-interactive-session-by-swami-swaroopananda-ji-2024-js": () => import("./../../../src/pages/school-events/interactive-session-by-swami-swaroopananda-ji-2024.js" /* webpackChunkName: "component---src-pages-school-events-interactive-session-by-swami-swaroopananda-ji-2024-js" */),
  "component---src-pages-school-events-interhouse-mine-show-assembly-2024-js": () => import("./../../../src/pages/school-events/interhouse-mine-show-assembly-2024.js" /* webpackChunkName: "component---src-pages-school-events-interhouse-mine-show-assembly-2024-js" */),
  "component---src-pages-school-events-international-day-of-persons-with-disabilities-2023-js": () => import("./../../../src/pages/school-events/international-day-of-persons-with-disabilities-2023.js" /* webpackChunkName: "component---src-pages-school-events-international-day-of-persons-with-disabilities-2023-js" */),
  "component---src-pages-school-events-international-tiger-day-2024-js": () => import("./../../../src/pages/school-events/international-tiger-day-2024.js" /* webpackChunkName: "component---src-pages-school-events-international-tiger-day-2024-js" */),
  "component---src-pages-school-events-international-tiger-day-js": () => import("./../../../src/pages/school-events/international-tiger-day.js" /* webpackChunkName: "component---src-pages-school-events-international-tiger-day-js" */),
  "component---src-pages-school-events-international-yoga-day-2023-js": () => import("./../../../src/pages/school-events/international-yoga-day-2023.js" /* webpackChunkName: "component---src-pages-school-events-international-yoga-day-2023-js" */),
  "component---src-pages-school-events-investigating-natures-wrath-natural-disasters-2024-js": () => import("./../../../src/pages/school-events/investigating-natures-wrath-natural-disasters-2024.js" /* webpackChunkName: "component---src-pages-school-events-investigating-natures-wrath-natural-disasters-2024-js" */),
  "component---src-pages-school-events-janmashtami-2024-js": () => import("./../../../src/pages/school-events/janmashtami-2024.js" /* webpackChunkName: "component---src-pages-school-events-janmashtami-2024-js" */),
  "component---src-pages-school-events-joy-of-giving-2023-js": () => import("./../../../src/pages/school-events/joy-of-giving-2023.js" /* webpackChunkName: "component---src-pages-school-events-joy-of-giving-2023-js" */),
  "component---src-pages-school-events-joy-of-giving-2024-js": () => import("./../../../src/pages/school-events/joy-of-giving-2024.js" /* webpackChunkName: "component---src-pages-school-events-joy-of-giving-2024-js" */),
  "component---src-pages-school-events-junior-class-annual-prize-distribution-ceremony-2023-js": () => import("./../../../src/pages/school-events/junior-class-annual-prize-distribution-ceremony-2023.js" /* webpackChunkName: "component---src-pages-school-events-junior-class-annual-prize-distribution-ceremony-2023-js" */),
  "component---src-pages-school-events-karadi-path-workshop-js": () => import("./../../../src/pages/school-events/karadi-path-workshop.js" /* webpackChunkName: "component---src-pages-school-events-karadi-path-workshop-js" */),
  "component---src-pages-school-events-karadi-tales-workshop-2024-js": () => import("./../../../src/pages/school-events/karadi-tales-workshop-2024.js" /* webpackChunkName: "component---src-pages-school-events-karadi-tales-workshop-2024-js" */),
  "component---src-pages-school-events-kargil-diwas-2024-js": () => import("./../../../src/pages/school-events/kargil-diwas-2024.js" /* webpackChunkName: "component---src-pages-school-events-kargil-diwas-2024-js" */),
  "component---src-pages-school-events-kargil-vijay-diwas-js": () => import("./../../../src/pages/school-events/kargil-vijay-diwas.js" /* webpackChunkName: "component---src-pages-school-events-kargil-vijay-diwas-js" */),
  "component---src-pages-school-events-karma-the-echoes-of-duty-2024-js": () => import("./../../../src/pages/school-events/karma-the-echoes-of-duty-2024.js" /* webpackChunkName: "component---src-pages-school-events-karma-the-echoes-of-duty-2024-js" */),
  "component---src-pages-school-events-kartavyapath-js": () => import("./../../../src/pages/school-events/kartavyapath.js" /* webpackChunkName: "component---src-pages-school-events-kartavyapath-js" */),
  "component---src-pages-school-events-keep-calm-study-hard-2024-js": () => import("./../../../src/pages/school-events/keep-calm-study-hard-2024.js" /* webpackChunkName: "component---src-pages-school-events-keep-calm-study-hard-2024-js" */),
  "component---src-pages-school-events-khel-mela-2024-js": () => import("./../../../src/pages/school-events/khel-mela-2024.js" /* webpackChunkName: "component---src-pages-school-events-khel-mela-2024-js" */),
  "component---src-pages-school-events-krishna-janmashtami-celebration-2023-js": () => import("./../../../src/pages/school-events/krishna-janmashtami-celebration-2023.js" /* webpackChunkName: "component---src-pages-school-events-krishna-janmashtami-celebration-2023-js" */),
  "component---src-pages-school-events-language-fiesta-the-english-week-primary-2024-js": () => import("./../../../src/pages/school-events/language-fiesta-the-english-week-primary-2024.js" /* webpackChunkName: "component---src-pages-school-events-language-fiesta-the-english-week-primary-2024-js" */),
  "component---src-pages-school-events-language-literacy-development-2024-js": () => import("./../../../src/pages/school-events/language-literacy-development-2024.js" /* webpackChunkName: "component---src-pages-school-events-language-literacy-development-2024-js" */),
  "component---src-pages-school-events-living-oneness-js": () => import("./../../../src/pages/school-events/living-oneness.js" /* webpackChunkName: "component---src-pages-school-events-living-oneness-js" */),
  "component---src-pages-school-events-lohri-celebration-of-pre-primary-online-session-2024-js": () => import("./../../../src/pages/school-events/lohri-celebration-of-pre-primary-online-session-2024.js" /* webpackChunkName: "component---src-pages-school-events-lohri-celebration-of-pre-primary-online-session-2024-js" */),
  "component---src-pages-school-events-masti-ki-pathshala-class-ix-2024-js": () => import("./../../../src/pages/school-events/masti-ki-pathshala-class-ix-2024.js" /* webpackChunkName: "component---src-pages-school-events-masti-ki-pathshala-class-ix-2024-js" */),
  "component---src-pages-school-events-masti-ki-pathshala-march-2024-js": () => import("./../../../src/pages/school-events/masti-ki-pathshala-march-2024.js" /* webpackChunkName: "component---src-pages-school-events-masti-ki-pathshala-march-2024-js" */),
  "component---src-pages-school-events-matr-pitr-abhinandan-js": () => import("./../../../src/pages/school-events/matr-pitr-abhinandan.js" /* webpackChunkName: "component---src-pages-school-events-matr-pitr-abhinandan-js" */),
  "component---src-pages-school-events-mental-health-and-emotional-well-being-2024-js": () => import("./../../../src/pages/school-events/mental-health-and-emotional-well-being-2024.js" /* webpackChunkName: "component---src-pages-school-events-mental-health-and-emotional-well-being-2024-js" */),
  "component---src-pages-school-events-mothers-day-2024-js": () => import("./../../../src/pages/school-events/mothers-day-2024.js" /* webpackChunkName: "component---src-pages-school-events-mothers-day-2024-js" */),
  "component---src-pages-school-events-my-health-my-right-assembly-2024-js": () => import("./../../../src/pages/school-events/my-health-my-right-assembly-2024.js" /* webpackChunkName: "component---src-pages-school-events-my-health-my-right-assembly-2024-js" */),
  "component---src-pages-school-events-nasa-exploration-session-2024-js": () => import("./../../../src/pages/school-events/nasa-exploration-session-2024.js" /* webpackChunkName: "component---src-pages-school-events-nasa-exploration-session-2024-js" */),
  "component---src-pages-school-events-national-mathematics-day-2023-js": () => import("./../../../src/pages/school-events/national-mathematics-day-2023.js" /* webpackChunkName: "component---src-pages-school-events-national-mathematics-day-2023-js" */),
  "component---src-pages-school-events-national-science-centre-visit-2024-js": () => import("./../../../src/pages/school-events/national-science-centre-visit-2024.js" /* webpackChunkName: "component---src-pages-school-events-national-science-centre-visit-2024-js" */),
  "component---src-pages-school-events-national-sports-day-2024-js": () => import("./../../../src/pages/school-events/national-sports-day-2024.js" /* webpackChunkName: "component---src-pages-school-events-national-sports-day-2024-js" */),
  "component---src-pages-school-events-national-stock-exchange-class-xi-commerce-and-economics-students-2024-js": () => import("./../../../src/pages/school-events/national-stock-exchange-class-xi-commerce-and-economics-students-2024.js" /* webpackChunkName: "component---src-pages-school-events-national-stock-exchange-class-xi-commerce-and-economics-students-2024-js" */),
  "component---src-pages-school-events-national-unity-day-assembly-by-class-iii-a-2024-js": () => import("./../../../src/pages/school-events/national-unity-day-assembly-by-class-iii-a-2024.js" /* webpackChunkName: "component---src-pages-school-events-national-unity-day-assembly-by-class-iii-a-2024-js" */),
  "component---src-pages-school-events-national-youth-day-assembly-2024-js": () => import("./../../../src/pages/school-events/national-youth-day-assembly-2024.js" /* webpackChunkName: "component---src-pages-school-events-national-youth-day-assembly-2024-js" */),
  "component---src-pages-school-events-nbt-pustak-parikarma-2024-js": () => import("./../../../src/pages/school-events/nbt-pustak-parikarma-2024.js" /* webpackChunkName: "component---src-pages-school-events-nbt-pustak-parikarma-2024-js" */),
  "component---src-pages-school-events-no-tobacco-day-2024-js": () => import("./../../../src/pages/school-events/no-tobacco-day-2024.js" /* webpackChunkName: "component---src-pages-school-events-no-tobacco-day-2024-js" */),
  "component---src-pages-school-events-nursery-adaptation-day-1-2024-js": () => import("./../../../src/pages/school-events/nursery-adaptation-day-1-2024.js" /* webpackChunkName: "component---src-pages-school-events-nursery-adaptation-day-1-2024-js" */),
  "component---src-pages-school-events-nursery-adaptation-day-2-2024-js": () => import("./../../../src/pages/school-events/nursery-adaptation-day-2-2024.js" /* webpackChunkName: "component---src-pages-school-events-nursery-adaptation-day-2-2024-js" */),
  "component---src-pages-school-events-nursery-adaptation-day-3-2024-js": () => import("./../../../src/pages/school-events/nursery-adaptation-day-3-2024.js" /* webpackChunkName: "component---src-pages-school-events-nursery-adaptation-day-3-2024-js" */),
  "component---src-pages-school-events-orientation-program-for-new-students-2024-js": () => import("./../../../src/pages/school-events/orientation-program-for-new-students-2024.js" /* webpackChunkName: "component---src-pages-school-events-orientation-program-for-new-students-2024-js" */),
  "component---src-pages-school-events-orientation-program-for-nur-to-xii-2024-js": () => import("./../../../src/pages/school-events/orientation-program-for-nur-to-xii-2024.js" /* webpackChunkName: "component---src-pages-school-events-orientation-program-for-nur-to-xii-2024-js" */),
  "component---src-pages-school-events-palate-fest-farm-to-fork-by-evs-department-classes-i-v-2024-js": () => import("./../../../src/pages/school-events/palate-fest-farm-to-fork-by-evs-department-classes-i-v-2024.js" /* webpackChunkName: "component---src-pages-school-events-palate-fest-farm-to-fork-by-evs-department-classes-i-v-2024-js" */),
  "component---src-pages-school-events-pariksha-pe-charcha-2024-js": () => import("./../../../src/pages/school-events/pariksha-pe-charcha-2024.js" /* webpackChunkName: "component---src-pages-school-events-pariksha-pe-charcha-2024-js" */),
  "component---src-pages-school-events-pass-the-ball-activity-pre-primary-2024-js": () => import("./../../../src/pages/school-events/pass-the-ball-activity-pre-primary-2024.js" /* webpackChunkName: "component---src-pages-school-events-pass-the-ball-activity-pre-primary-2024-js" */),
  "component---src-pages-school-events-photography-videography-workshop-2024-js": () => import("./../../../src/pages/school-events/photography-videography-workshop-2024.js" /* webpackChunkName: "component---src-pages-school-events-photography-videography-workshop-2024-js" */),
  "component---src-pages-school-events-plastic-waste-collection-drive-2024-js": () => import("./../../../src/pages/school-events/plastic-waste-collection-drive-2024.js" /* webpackChunkName: "component---src-pages-school-events-plastic-waste-collection-drive-2024-js" */),
  "component---src-pages-school-events-pm-yuva-author-meet-class-viii-2024-js": () => import("./../../../src/pages/school-events/pm-yuva-author-meet-class-viii-2024.js" /* webpackChunkName: "component---src-pages-school-events-pm-yuva-author-meet-class-viii-2024-js" */),
  "component---src-pages-school-events-poster-making-classes-ii-to-xii-2024-js": () => import("./../../../src/pages/school-events/poster-making-classes-ii-to-xii-2024.js" /* webpackChunkName: "component---src-pages-school-events-poster-making-classes-ii-to-xii-2024-js" */),
  "component---src-pages-school-events-posture-matters-a-journey-overall-well-being-js": () => import("./../../../src/pages/school-events/posture-matters-a-journey-overall-well-being.js" /* webpackChunkName: "component---src-pages-school-events-posture-matters-a-journey-overall-well-being-js" */),
  "component---src-pages-school-events-price-distribution-ceremony-2023-js": () => import("./../../../src/pages/school-events/price-distribution-ceremony-2023.js" /* webpackChunkName: "component---src-pages-school-events-price-distribution-ceremony-2023-js" */),
  "component---src-pages-school-events-primary-investiture-ceremony-2023-js": () => import("./../../../src/pages/school-events/primary-investiture-ceremony-2023.js" /* webpackChunkName: "component---src-pages-school-events-primary-investiture-ceremony-2023-js" */),
  "component---src-pages-school-events-primary-investiture-ceremony-2024-js": () => import("./../../../src/pages/school-events/primary-investiture-ceremony-2024.js" /* webpackChunkName: "component---src-pages-school-events-primary-investiture-ceremony-2024-js" */),
  "component---src-pages-school-events-prize-distribution-assembly-2024-js": () => import("./../../../src/pages/school-events/prize-distribution-assembly-2024.js" /* webpackChunkName: "component---src-pages-school-events-prize-distribution-assembly-2024-js" */),
  "component---src-pages-school-events-prs-youth-parliament-2024-js": () => import("./../../../src/pages/school-events/prs-youth-parliament-2024.js" /* webpackChunkName: "component---src-pages-school-events-prs-youth-parliament-2024-js" */),
  "component---src-pages-school-events-puppet-show-enthralls-our-young-learners-js": () => import("./../../../src/pages/school-events/puppet-show-enthralls-our-young-learners.js" /* webpackChunkName: "component---src-pages-school-events-puppet-show-enthralls-our-young-learners-js" */),
  "component---src-pages-school-events-puppet-show-js": () => import("./../../../src/pages/school-events/puppet-show.js" /* webpackChunkName: "component---src-pages-school-events-puppet-show-js" */),
  "component---src-pages-school-events-ram-navami-assembly-2024-js": () => import("./../../../src/pages/school-events/ram-navami-assembly-2024.js" /* webpackChunkName: "component---src-pages-school-events-ram-navami-assembly-2024-js" */),
  "component---src-pages-school-events-red-day-nursery-2024-js": () => import("./../../../src/pages/school-events/red-day-nursery-2024.js" /* webpackChunkName: "component---src-pages-school-events-red-day-nursery-2024-js" */),
  "component---src-pages-school-events-rids-activity-nourish-to-flourish-2024-js": () => import("./../../../src/pages/school-events/rids-activity-nourish-to-flourish-2024.js" /* webpackChunkName: "component---src-pages-school-events-rids-activity-nourish-to-flourish-2024-js" */),
  "component---src-pages-school-events-rids-class-presentation-by-class-iv-once-upon-a-time-in-oman-2024-js": () => import("./../../../src/pages/school-events/rids-class-presentation-by-class-iv-once-upon-a-time-in-oman-2024.js" /* webpackChunkName: "component---src-pages-school-events-rids-class-presentation-by-class-iv-once-upon-a-time-in-oman-2024-js" */),
  "component---src-pages-school-events-rids-class-presentation-the-desert-story-unfolds-by-class-iii-2024-js": () => import("./../../../src/pages/school-events/rids-class-presentation-the-desert-story-unfolds-by-class-iii-2024.js" /* webpackChunkName: "component---src-pages-school-events-rids-class-presentation-the-desert-story-unfolds-by-class-iii-2024-js" */),
  "component---src-pages-school-events-rids-monumental-marvels-trip-to-humayuns-tomb-2024-js": () => import("./../../../src/pages/school-events/rids-monumental-marvels-trip-to-humayuns-tomb-2024.js" /* webpackChunkName: "component---src-pages-school-events-rids-monumental-marvels-trip-to-humayuns-tomb-2024-js" */),
  "component---src-pages-school-events-role-play-activity-2024-js": () => import("./../../../src/pages/school-events/role-play-activity-2024.js" /* webpackChunkName: "component---src-pages-school-events-role-play-activity-2024-js" */),
  "component---src-pages-school-events-run-o-mania-activity-2024-js": () => import("./../../../src/pages/school-events/run-o-mania-activity-2024.js" /* webpackChunkName: "component---src-pages-school-events-run-o-mania-activity-2024-js" */),
  "component---src-pages-school-events-safe-and-unsafe-touch-2023-js": () => import("./../../../src/pages/school-events/safe-and-unsafe-touch-2023.js" /* webpackChunkName: "component---src-pages-school-events-safe-and-unsafe-touch-2023-js" */),
  "component---src-pages-school-events-safe-unsafe-touch-2024-js": () => import("./../../../src/pages/school-events/safe-unsafe-touch-2024.js" /* webpackChunkName: "component---src-pages-school-events-safe-unsafe-touch-2024-js" */),
  "component---src-pages-school-events-sanskrit-divas-assembly-2024-js": () => import("./../../../src/pages/school-events/sanskrit-divas-assembly-2024.js" /* webpackChunkName: "component---src-pages-school-events-sanskrit-divas-assembly-2024-js" */),
  "component---src-pages-school-events-senior-class-annual-prize-distribution-ceremony-2023-js": () => import("./../../../src/pages/school-events/senior-class-annual-prize-distribution-ceremony-2023.js" /* webpackChunkName: "component---src-pages-school-events-senior-class-annual-prize-distribution-ceremony-2023-js" */),
  "component---src-pages-school-events-senior-investiture-ceremony-2024-js": () => import("./../../../src/pages/school-events/senior-investiture-ceremony-2024.js" /* webpackChunkName: "component---src-pages-school-events-senior-investiture-ceremony-2024-js" */),
  "component---src-pages-school-events-sensorial-activity-2024-js": () => import("./../../../src/pages/school-events/sensorial-activity-2024.js" /* webpackChunkName: "component---src-pages-school-events-sensorial-activity-2024-js" */),
  "component---src-pages-school-events-session-on-career-preparedness-after-xii-2024-js": () => import("./../../../src/pages/school-events/session-on-career-preparedness-after-xii-2024.js" /* webpackChunkName: "component---src-pages-school-events-session-on-career-preparedness-after-xii-2024-js" */),
  "component---src-pages-school-events-sewa-divas-108th-gurudev-jayanti-2024-js": () => import("./../../../src/pages/school-events/sewa-divas-108th-gurudev-jayanti-2024.js" /* webpackChunkName: "component---src-pages-school-events-sewa-divas-108th-gurudev-jayanti-2024-js" */),
  "component---src-pages-school-events-sewa-session-july-2024-js": () => import("./../../../src/pages/school-events/sewa-session-july-2024.js" /* webpackChunkName: "component---src-pages-school-events-sewa-session-july-2024-js" */),
  "component---src-pages-school-events-sewa-session-primary-department-2024-js": () => import("./../../../src/pages/school-events/sewa-session-primary-department-2024.js" /* webpackChunkName: "component---src-pages-school-events-sewa-session-primary-department-2024-js" */),
  "component---src-pages-school-events-shape-o-mania-activity-js": () => import("./../../../src/pages/school-events/shape-o-mania-activity.js" /* webpackChunkName: "component---src-pages-school-events-shape-o-mania-activity-js" */),
  "component---src-pages-school-events-shri-ram-pujan-in-the-school-js": () => import("./../../../src/pages/school-events/shri-ram-pujan-in-the-school.js" /* webpackChunkName: "component---src-pages-school-events-shri-ram-pujan-in-the-school-js" */),
  "component---src-pages-school-events-soccer-activity-2024-js": () => import("./../../../src/pages/school-events/soccer-activity-2024.js" /* webpackChunkName: "component---src-pages-school-events-soccer-activity-2024-js" */),
  "component---src-pages-school-events-space-club-session-i-2024-js": () => import("./../../../src/pages/school-events/space-club-session-I-2024.js" /* webpackChunkName: "component---src-pages-school-events-space-club-session-i-2024-js" */),
  "component---src-pages-school-events-splish-splash-js": () => import("./../../../src/pages/school-events/splish-splash.js" /* webpackChunkName: "component---src-pages-school-events-splish-splash-js" */),
  "component---src-pages-school-events-sqaaf-session-by-ms-shanti-krishnamurthy-2024-js": () => import("./../../../src/pages/school-events/sqaaf-session-by-ms-shanti-krishnamurthy-2024.js" /* webpackChunkName: "component---src-pages-school-events-sqaaf-session-by-ms-shanti-krishnamurthy-2024-js" */),
  "component---src-pages-school-events-storytelling-session-class-vii-2024-js": () => import("./../../../src/pages/school-events/storytelling-session-class-vii-2024.js" /* webpackChunkName: "component---src-pages-school-events-storytelling-session-class-vii-2024-js" */),
  "component---src-pages-school-events-superpower-of-being-human-2024-js": () => import("./../../../src/pages/school-events/superpower-of-being-human-2024.js" /* webpackChunkName: "component---src-pages-school-events-superpower-of-being-human-2024-js" */),
  "component---src-pages-school-events-teachers-day-2024-js": () => import("./../../../src/pages/school-events/teachers-day-2024.js" /* webpackChunkName: "component---src-pages-school-events-teachers-day-2024-js" */),
  "component---src-pages-school-events-teachers-day-celebration-2023-js": () => import("./../../../src/pages/school-events/teachers-day-celebration-2023.js" /* webpackChunkName: "component---src-pages-school-events-teachers-day-celebration-2023-js" */),
  "component---src-pages-school-events-the-28th-inter-school-bhagvad-geeta-chanting-competition-3rd-to-5th-oct-2024-js": () => import("./../../../src/pages/school-events/the-28th-inter-school-bhagvad-geeta-chanting-competition-3rd-to-5th-oct-2024.js" /* webpackChunkName: "component---src-pages-school-events-the-28th-inter-school-bhagvad-geeta-chanting-competition-3rd-to-5th-oct-2024-js" */),
  "component---src-pages-school-events-the-echo-of-new-india-js": () => import("./../../../src/pages/school-events/the-echo-of-new-india.js" /* webpackChunkName: "component---src-pages-school-events-the-echo-of-new-india-js" */),
  "component---src-pages-school-events-the-foundation-of-success-2024-js": () => import("./../../../src/pages/school-events/the-foundation-of-success-2024.js" /* webpackChunkName: "component---src-pages-school-events-the-foundation-of-success-2024-js" */),
  "component---src-pages-school-events-the-kiddies-kitchenette-2024-js": () => import("./../../../src/pages/school-events/the-kiddies-kitchenette-2024.js" /* webpackChunkName: "component---src-pages-school-events-the-kiddies-kitchenette-2024-js" */),
  "component---src-pages-school-events-the-majestic-nature-by-nursery-2023-js": () => import("./../../../src/pages/school-events/the-majestic-nature-by-nursery-2023.js" /* webpackChunkName: "component---src-pages-school-events-the-majestic-nature-by-nursery-2023-js" */),
  "component---src-pages-school-events-trip-to-jim-corbett-and-nainital-2024-js": () => import("./../../../src/pages/school-events/trip-to-jim-corbett-and-nainital-2024.js" /* webpackChunkName: "component---src-pages-school-events-trip-to-jim-corbett-and-nainital-2024-js" */),
  "component---src-pages-school-events-unleashing-imagination-through-puppetry-2024-js": () => import("./../../../src/pages/school-events/unleashing-imagination-through-puppetry-2024.js" /* webpackChunkName: "component---src-pages-school-events-unleashing-imagination-through-puppetry-2024-js" */),
  "component---src-pages-school-events-vasant-panchami-saraswati-pooja-2024-js": () => import("./../../../src/pages/school-events/vasant-panchami-saraswati-pooja-2024.js" /* webpackChunkName: "component---src-pages-school-events-vasant-panchami-saraswati-pooja-2024-js" */),
  "component---src-pages-school-events-vasudhaiva-kutumbakam-2024-js": () => import("./../../../src/pages/school-events/vasudhaiva-kutumbakam-2024.js" /* webpackChunkName: "component---src-pages-school-events-vasudhaiva-kutumbakam-2024-js" */),
  "component---src-pages-school-events-visit-at-soil-gurugram-2023-js": () => import("./../../../src/pages/school-events/visit-at-soil-gurugram-2023.js" /* webpackChunkName: "component---src-pages-school-events-visit-at-soil-gurugram-2023-js" */),
  "component---src-pages-school-events-visit-of-cultural-exchange-embracing-indian-spiritual-heritage-2024-js": () => import("./../../../src/pages/school-events/visit-of-cultural-exchange-embracing-indian-spiritual-heritage-2024.js" /* webpackChunkName: "component---src-pages-school-events-visit-of-cultural-exchange-embracing-indian-spiritual-heritage-2024-js" */),
  "component---src-pages-school-events-visit-to-the-parliament-house-2024-js": () => import("./../../../src/pages/school-events/visit-to-the-parliament-house-2024.js" /* webpackChunkName: "component---src-pages-school-events-visit-to-the-parliament-house-2024-js" */),
  "component---src-pages-school-events-waste-garbage-given-to-green-dream-foundation-2024-js": () => import("./../../../src/pages/school-events/waste-garbage-given-to-green-dream-foundation-2024.js" /* webpackChunkName: "component---src-pages-school-events-waste-garbage-given-to-green-dream-foundation-2024-js" */),
  "component---src-pages-school-events-welcome-back-to-school-activity-2024-js": () => import("./../../../src/pages/school-events/welcome-back-to-school-activity-2024.js" /* webpackChunkName: "component---src-pages-school-events-welcome-back-to-school-activity-2024-js" */),
  "component---src-pages-school-events-wild-about-animals-2023-js": () => import("./../../../src/pages/school-events/wild-about-animals-2023.js" /* webpackChunkName: "component---src-pages-school-events-wild-about-animals-2023-js" */),
  "component---src-pages-school-events-workshop-on-digital-tools-of-interactive-panel-2024-js": () => import("./../../../src/pages/school-events/workshop-on-digital-tools-of-interactive-panel-2024.js" /* webpackChunkName: "component---src-pages-school-events-workshop-on-digital-tools-of-interactive-panel-2024-js" */),
  "component---src-pages-school-events-workshop-on-pocso-and-posh-js": () => import("./../../../src/pages/school-events/workshop-on-pocso-and-posh.js" /* webpackChunkName: "component---src-pages-school-events-workshop-on-pocso-and-posh-js" */),
  "component---src-pages-school-events-workshop-on-preparedness-for-medical-emergencies-2024-js": () => import("./../../../src/pages/school-events/workshop-on-preparedness-for-medical-emergencies-2024.js" /* webpackChunkName: "component---src-pages-school-events-workshop-on-preparedness-for-medical-emergencies-2024-js" */),
  "component---src-pages-school-events-workshop-on-reflections-on-school-practices-in-alignment-with-nep-2020-js": () => import("./../../../src/pages/school-events/workshop-on-reflections-on-school-practices-in-alignment-with-nep-2020.js" /* webpackChunkName: "component---src-pages-school-events-workshop-on-reflections-on-school-practices-in-alignment-with-nep-2020-js" */),
  "component---src-pages-school-events-workshop-on-toy-pedagogy-2024-js": () => import("./../../../src/pages/school-events/workshop-on-toy-pedagogy-2024.js" /* webpackChunkName: "component---src-pages-school-events-workshop-on-toy-pedagogy-2024-js" */),
  "component---src-pages-school-events-workshop-on-video-editing-clipchamp-2024-js": () => import("./../../../src/pages/school-events/workshop-on-video-editing-clipchamp-2024.js" /* webpackChunkName: "component---src-pages-school-events-workshop-on-video-editing-clipchamp-2024-js" */),
  "component---src-pages-school-events-world-art-day-2024-js": () => import("./../../../src/pages/school-events/world-art-day-2024.js" /* webpackChunkName: "component---src-pages-school-events-world-art-day-2024-js" */),
  "component---src-pages-school-events-world-dance-day-2024-js": () => import("./../../../src/pages/school-events/world-dance-day-2024.js" /* webpackChunkName: "component---src-pages-school-events-world-dance-day-2024-js" */),
  "component---src-pages-school-events-world-disability-day-2023-js": () => import("./../../../src/pages/school-events/world-disability-day-2023.js" /* webpackChunkName: "component---src-pages-school-events-world-disability-day-2023-js" */),
  "component---src-pages-school-events-world-earth-day-2024-js": () => import("./../../../src/pages/school-events/world-earth-day-2024.js" /* webpackChunkName: "component---src-pages-school-events-world-earth-day-2024-js" */),
  "component---src-pages-school-events-world-environment-day-2024-js": () => import("./../../../src/pages/school-events/world-environment-day-2024.js" /* webpackChunkName: "component---src-pages-school-events-world-environment-day-2024-js" */),
  "component---src-pages-school-events-world-literacy-day-2023-js": () => import("./../../../src/pages/school-events/world-literacy-day-2023.js" /* webpackChunkName: "component---src-pages-school-events-world-literacy-day-2023-js" */),
  "component---src-pages-school-events-world-mental-health-day-by-honesty-house-2024-js": () => import("./../../../src/pages/school-events/world-mental-health-day-by-honesty-house-2024.js" /* webpackChunkName: "component---src-pages-school-events-world-mental-health-day-by-honesty-house-2024-js" */),
  "component---src-pages-school-events-world-ozone-day-2024-js": () => import("./../../../src/pages/school-events/world-ozone-day-2024.js" /* webpackChunkName: "component---src-pages-school-events-world-ozone-day-2024-js" */),
  "component---src-pages-school-events-world-peace-day-2024-js": () => import("./../../../src/pages/school-events/world-peace-day-2024.js" /* webpackChunkName: "component---src-pages-school-events-world-peace-day-2024-js" */),
  "component---src-pages-school-events-yellow-day-celebrations-js": () => import("./../../../src/pages/school-events/yellow-day-celebrations.js" /* webpackChunkName: "component---src-pages-school-events-yellow-day-celebrations-js" */),
  "component---src-pages-school-gallery-js": () => import("./../../../src/pages/school-gallery.js" /* webpackChunkName: "component---src-pages-school-gallery-js" */),
  "component---src-pages-school-uniform-js": () => import("./../../../src/pages/school-uniform.js" /* webpackChunkName: "component---src-pages-school-uniform-js" */),
  "component---src-pages-sports-js": () => import("./../../../src/pages/sports.js" /* webpackChunkName: "component---src-pages-sports-js" */),
  "component---src-pages-subjects-js": () => import("./../../../src/pages/subjects.js" /* webpackChunkName: "component---src-pages-subjects-js" */),
  "component---src-pages-syllabus-js": () => import("./../../../src/pages/syllabus.js" /* webpackChunkName: "component---src-pages-syllabus-js" */),
  "component---src-pages-transfer-certificate-js": () => import("./../../../src/pages/transfer-certificate.js" /* webpackChunkName: "component---src-pages-transfer-certificate-js" */),
  "component---src-pages-transportation-js": () => import("./../../../src/pages/transportation.js" /* webpackChunkName: "component---src-pages-transportation-js" */),
  "component---src-pages-visual-arts-js": () => import("./../../../src/pages/visual-arts.js" /* webpackChunkName: "component---src-pages-visual-arts-js" */),
  "component---src-pages-vyaktitva-js": () => import("./../../../src/pages/vyaktitva.js" /* webpackChunkName: "component---src-pages-vyaktitva-js" */)
}

